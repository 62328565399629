<style scoped lang="less">
.t_paper_review {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
}
</style>
<template>
  <div class="t_paper_review">
    <div class="stu-module-header">
      <div class="stu-module-title">论文评审</div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline>
        <el-form-item label="姓名/学号">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="毕业批次">
          <el-select v-model="form.batch" :clearable="true">
            <el-option
              v-for="(item, index) in batchOptions"
              :key="index"
              :label="item.batchName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="form.auditState" :clearable="true">
            <el-option
              v-for="(item, index) in auditStateOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="height"
        @selection-change="selectionChange"
        stripe
      >
        <el-table-column
          type="selection"
          width="65"
          align="center"
          fixed
        ></el-table-column>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.width"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="审核" prop="" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="audit(scope.row, scope.$index)">
              查看审核
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="提交历史"
          prop=""
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>

      <!-- 添加 -->
      <el-dialog
          title="审核"
          :visible.sync="dialogVisible"
          width="50%"
          style="text-align: left"
      >
        <el-form
            size="small"
            :model="bo"
            ref="ruleForm"
            class="demo-ruleForm"
            label-width="120px"
        >

          <el-row>
            <el-col :span="12">
              <el-form-item  label="姓名："  prop="jobNo">
                <div>{{ bo.name }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="学号："  prop="jobNo">
                <div>{{ bo.studentNo }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="专业："  prop="jobNo">
                <div>{{ bo.profName }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="联系方式："  prop="jobNo">
                <div>{{ bo.contactPhone }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="提交时间："  prop="jobNo">
                <div>{{ bo.submitTime }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="审核时间："  prop="jobNo">
                <div>{{ bo.updateTime }}</div>
              </el-form-item>
            </el-col>
              <el-col :span="12">
                <el-form-item  label="毕业类型："  prop="jobNo">
                  <el-select v-model="bo.graduateType" disabled >
                    <el-option
                        v-for="(item, index) in graduateTypeOptions"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            <el-col :span="12">
              <el-form-item  label="分数："  prop="jobNo">
                <div>{{ bo.score }}</div>
              </el-form-item>
            </el-col>
            <el-col>
              <el-col :span="12">
                <el-form-item  label=""  prop="jobNo">

                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="审核状态："  prop="jobNo">
                <el-select v-model="bo.paperStatus">
                  <el-option
                      v-for="(item, index) in auditStateOptions"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="审核附件："  prop="jobNo">
                <el-button @click="download" type="text">下载</el-button>
                <el-upload
                    ref="upload"
                    action="#"
                    :auto-upload="false"
                    :http-request="uploadS"
                    :on-change="uploadChange"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item  label="审核评语："  prop="jobNo">
                <el-input
                    v-model="bo.stuffInfo"
                    placeholder="请输入内容"
                    style="width: 300px;"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item v-show="bo.paperStatus==2" label="成绩："  prop="jobNo">
                <el-input
                    v-model="bo.score"
                    placeholder="请输入内容"
                    style="width: 300px;height: 100px"
                ></el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-button style="margin-bottom: 40px;margin-left: 500px" @click="edit" type="primary">审核</el-button>
        </el-form>
      </el-dialog>

    </div>
  </div>
</template>

<script>
export default {
  name: "t_paper_review",
  components: {},
  data() {
    return {
      dialogVisible: false,
      bo: {
        name: null,
        id:null,
        studentNo: null,
        profName: null,
        contactPhone: null,
        submitTime: null,
        updateTime: null,
        graduateType: "",
        paperStatus: "",
        paperStatues: "",
        stuffUrl: null,
        stuffInfo: null,
        score: null,
        attachmentUrl: null,
      },
      height: window.innerHeight - 380,
      form: {
        name: null,
        batch: null,
        auditState:null,
      },
      batchOptions: [],
      graduateTypeOptions:[
        { label: "答辩", value: 0 },
        { label: "评审", value: 1 },
      ],
      auditStateOptions: [
        { label: "未提交", value: 0 },
        { label: "等待审核", value: 1 },
        { label: "审核通过", value: 2 },
        { label: "审核驳回", value: 3 },
      ],
      column: [
        { label: "姓名", prop: "name" },
        { label: "学号", prop: "studentNo" },
        { label: "专业", prop: "profName" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "提交时间", prop: "submitTime" },
        { label: "审核状态", prop: "paperStatuss" },
      ],
      selectionData: [],
      tableData: [],
      total: 0,
      pageSize: 20,
      current: 1,
    };
  },
  mounted() {
    this.init();
    this.selectionInit();
  },
  methods: {
    init() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduGraduateDesignStu/queryStuffByStu",
        method: 'get',
        params: {
          page: this.current,
          pageSize: this.pageSize
        }
      }).then((resp) => {
        if (resp.data.code === 2000) {
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    selectionInit(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduGraduateDesignBatch/queryEduGraduateDesignBatch").then((resp)=>{
        if (resp.data.code === 2000) {
          this.batchOptions = resp.data.data;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    query() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduGraduateDesignStu/queryStuffByStu",
        method: 'get',
        params: {
          page: this.current,
          pageSize: this.pageSize,
          studentNameOrNum: this.form.name,
          paperStatus: this.form.auditState,
          batchId: this.form.batch
        }
      }).then((resp) => {
        if (resp.data.code === 2000) {
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    audit(row, index) {
      // if(row.paperStatus!=1){
      //   this.$message.info("该学生已经审核")
      // }else {
     // this.$refs.upload.clearFiles()
        this.dialogVisible = true;
        this.$axios_supermallData({
          url: "hnjxjy-core/eduGraduateDesignStu/queryStuffByStuId",
          method: 'get',
          params: {
            studentId: row.studentId,
          }
        }).then((resp) => {
          if (resp.data.code === 2000) {
            this.bo = resp.data.data;
            this.bo.id = row.id
            this.bo.stuffUrl = row.stuffUrl
            this.bo.paperStatues = row.paperStatus
          }
        });
      //}
    },
    download(){
      if(this.bo.attachmentUrl != null && this.bo.attachmentUrl!==""){
        window.open(this.bo.attachmentUrl)
      }else {
        this.$message({
          type:"error",
          message:"附件为空"
        })
      }
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    uploadChange(file){
      this.bo.file = file.raw
    },
    uploadS(){

    },
      edit(){
        let fromData = new FormData();
        if (this.bo.file !== undefined && null != this.bo.file){
          fromData.append("file",this.bo.file);
        }
        if (null != this.bo.stuffInfo){
          fromData.append("stuffInfo",this.bo.stuffInfo);
        }
        if (null != this.bo.score){
          fromData.append("score",this.bo.score);
        }
        if (null != this.bo.paperStatus){
          fromData.append("paperStatus",this.bo.paperStatus);
        }
        if (null != this.bo.id){
          fromData.append("id",this.bo.id);
        }
        // fromData.append("graduateType",this.bo.graduateType);
        if(this.bo.paperStatues !== 1){
          this.$message.info("该学生已经审核")
        }else{
          this.$axios_supermallData.post("hnjxjy-core/eduGraduateDesignStu/checkStuff",fromData).then(resp => {
            if (resp.data.code === 2000){
              this.$message.success("操作完成");
              this.init();
              this.dialogVisible = false;
            }else {
              this.$message.warning(resp.data.message);
            }
          })
        }

    },
  },
};
</script>
